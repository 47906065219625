/* eslint-disable react/react-in-jsx-scope */
import { h } from "preact";
import {
	closeOutQuestions,
	closeOutQuestionnaire,
} from "../../../../../lib/data";

interface CloseOutQuestionnaireViewProps {
	questionnaire: closeOutQuestionnaire;
}

const CloseOutQuestionnaireView = (
	props: CloseOutQuestionnaireViewProps,
): JSX.Element => (
	<div>
		<ul className="questionnaire">
			<li>
				<b>{closeOutQuestions.timesSpoken}</b>
			</li>
			<ul>
				<li>{props.questionnaire.timesSpoken}</li>
			</ul>
			<li>
				<b>{closeOutQuestions.comms}</b>
			</li>
			<ul className="questionnaire">
				{props.questionnaire.comms.phone && <li>Phone call</li>}
				{props.questionnaire.comms.email && <li>Email</li>}
				{props.questionnaire.comms.sms && <li>Text message</li>}
				{props.questionnaire.comms.video && <li>Video call</li>}
			</ul>
			<li>
				<b>{closeOutQuestions.intake}</b>
			</li>
			<ul>
				<li>{props.questionnaire.intake ? "Yes" : "No"}</li>
			</ul>
			<li>
				<b>{closeOutQuestions.extantAttorney}</b>
			</li>
			<ul>
				<li>{props.questionnaire.extantAttorney ? "Yes" : "No"}</li>
			</ul>
			<li>
				<b>{closeOutQuestions.caseMemo}</b>
			</li>
			<ul>
				<li>{props.questionnaire.caseMemo ? "Yes" : "No"}</li>
			</ul>
			<li>
				<b>{closeOutQuestions.feedback}</b>
			</li>
			<ul>
				<li>{props.questionnaire.feedback ? "Yes" : "No"}</li>
			</ul>
			<li>
				<b>{closeOutQuestions.decision}</b>
			</li>
			<ul>
				<li>{props.questionnaire.decision ? "Yes" : "No"}</li>
			</ul>
		</ul>
	</div>
);

export default CloseOutQuestionnaireView;

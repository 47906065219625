/* eslint-disable react/react-in-jsx-scope */
import "preact/debug";
import { h } from "preact";
import { StoreProvider } from "@preact-hooks/unistore";
import { Router } from "preact-router";

import { LoginPage } from "../../../../lib/components/login";
import { EnterBackupCodesPage } from "../../../../lib/components/backup-codes/recover";
import { Navbar } from "../../../../lib/components/legal-nav";
import { AuthClientContext, renderShell } from "../../../../lib/init";
import { DLOCClient } from "../../client";
import { store } from "../../../../lib/init/store";

import { DashboardPage } from "./pages/dashboard";
import { AccountSettingsPage } from "./pages/account-settings";
import { DlocClientContext } from "./components/DlocClientContext";
import { AuthRouter } from "../../../../lib/components/AuthRouter";
import { WithIdleTimer } from "../../../../lib/components/WithIdleTimer";
import { SelectLocPlus } from "./pages/select-loc-plus";
import { ViewCase } from "./pages/view-case";
import { DlocAdmin } from "./pages/admin";
import { useEffect, useState } from "preact/hooks";
import { DlocSignupToken } from "./pages/signup-token";
import { DlocCreateCredentials } from "./pages/create-credentials/DlocCreateCredentials";
import { LocAdmin } from "./pages/loc-admin";
import { RecoverySetup } from "../../../../lib/components/recovery-setup";
import { ParseAccountRecoverytoken } from "../../../../lib/components/ParseAccountRecoveryToken";
import { AnswerSecurityQuestions } from "../../../../lib/components/security-questions/AnswerSecurityQuestions";

const client = new DLOCClient();

const Main = () => {
	const [envVars, setEnvVars] = useState<Record<string, string> | null>(null);

	useEffect(() => {
		const getEnvVars = async () => {
			setEnvVars(await client.getEnvironmentVariables());
		};

		if (!envVars) {
			void getEnvVars();
		}
	}, []);

	return (
		<AuthClientContext.Provider value={client}>
			<DlocClientContext.Provider value={client}>
				<StoreProvider value={store}>
					<WithIdleTimer>
						<Router>
							<LoginPage
								path="/"
								target="/dashboard"
								client={client}
								portalName="DLOC Portal"
							/>
							<LoginPage
								path="/invite-loc/token/:token"
								target="/select-loc-plus"
								client={client}
								portalName="DLOC Portal"
							/>
							<DlocSignupToken path="/create-account/token/:token" />
							<DlocCreateCredentials
								client={client}
								path="/create-credentials/"
							/>
							<EnterBackupCodesPage path="/reset-password" client={client} />
							<ParseAccountRecoverytoken
								path="/recover-account/token/:token"
								client={client}
							/>
							<AnswerSecurityQuestions
								client={client}
								type="dloc"
								path="/recover-account/security-questions"
							/>
							<AuthRouter path="/:rest+">
								<DashboardPage path="/dashboard" />
								<AccountSettingsPage path="/account" />
								<SelectLocPlus client={client} path="/select-loc-plus" />
								<ViewCase path="/case/:id" />
								<DlocAdmin path="/admin" />
								<LocAdmin path="/locs" />
								<RecoverySetup client={client} path="/setup-recovery/" />
							</AuthRouter>
						</Router>
						<Navbar
							navItems={[
								{ title: "LOC Administration", url: "locs", newTab: false },
								{
									title: "DLOC and Key Administration",
									url: "admin",
									newTab: false,
								},
							]}
						/>
					</WithIdleTimer>
				</StoreProvider>
			</DlocClientContext.Provider>
		</AuthClientContext.Provider>
	);
};

void renderShell(<Main />, client);

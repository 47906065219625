import * as t from "io-ts";
import { tCaseStatus, tSodiumBytes, tSodiumDate } from "./util/iots";

export const dlocRpcSpec = {
	GetKeysForCaseAssignment: {
		input: t.type({
			entryId: t.string,
			locId: t.string,
			survivorId: t.string,
		}),
		output: t.type({
			entryKey: t.union([tSodiumBytes, t.undefined]),
			contactInfoKey: t.union([tSodiumBytes, t.undefined]),
			locPublicKey: tSodiumBytes,
			dlocKeyVersionForEntryKey: t.union([t.number, t.undefined]),
			dlocKeyVersionForContactInfoKey: t.union([t.number, t.undefined]),
		}),
		XXX_THINK_CAREFULLY_no_token: true,
	},

	AssignCase: {
		input: t.type({
			entryId: t.string,
			locId: t.string,
			survivorId: t.string,
			entryKeyLoc: tSodiumBytes,
			contactInfoKeyLoc: t.union([tSodiumBytes, t.null]),
			notes: t.array(
				t.type({
					notesId: t.string,
					encrypted: tSodiumBytes,
				}),
			),
		}),
		output: t.boolean,
	},

	UnassignCase: {
		input: t.type({
			entryId: t.string,
			locId: t.string,
			survivorId: t.union([t.string, t.null]),
		}),
		output: t.boolean,
	},

	AssignLocStar: {
		input: t.type({
			locId: t.string,
		}),
		output: t.unknown,
	},

	GetLocNotesForCase: {
		input: t.type({
			caseId: t.string,
		}),
		output: t.type({
			notes: t.array(
				t.type({
					notesId: t.string,
					encrypted: tSodiumBytes,
					dlocKeyVersion: t.number,
				}),
			),
		}),
		XXX_THINK_CAREFULLY_no_token: true,
	},

	SaveDlocCaseNotes: {
		input: t.type({
			caseId: t.string,
			encryptedNotes: tSodiumBytes,
			dlocKeyVersion: t.number,
		}),
		output: t.type({
			success: t.boolean,
		}),
	},

	DlocUpdateStatus: {
		input: t.type({
			entryId: t.string,
			status: tCaseStatus,
		}),
		output: t.type({
			success: t.boolean,
		}),
	},

	GetSharedKeysForDloc: {
		input: t.unknown,
		output: t.type({
			keys: t.array(
				t.type({
					version: t.number,
					encryptedKey: tSodiumBytes,
					publicKey: tSodiumBytes,
				}),
			),
		}),
		XXX_THINK_CAREFULLY_no_token: true,
	},

	GetDlocPublicKeys: {
		input: t.unknown,
		output: t.type({
			keys: t.record(t.string, tSodiumBytes),
		}),
		XXX_THINK_CAREFULLY_no_token: true,
	},

	SaveVersionedSharedDlocKeys: {
		input: t.type({
			version: t.number,
			sharedPublicKey: tSodiumBytes,
			encryptedSecretKeys: t.array(
				t.type({
					dlocId: t.string,
					encryptedKey: tSodiumBytes,
				}),
			),
		}),
		output: t.type({
			success: t.boolean,
		}),
	},

	GetDataForDlocReencryption: {
		input: t.unknown,
		output: t.type({
			entryData: t.array(
				t.type({
					entryId: t.string,
					assignmentData: t.type({
						dlocKeyVersion: t.number,
						encryptedAssignmentData: tSodiumBytes,
					}),
					questionnaire: t.union([
						t.null,
						t.type({
							dlocKeyVersion: t.union([t.null, t.number]),
							encryptedQuestionnaire: tSodiumBytes,
						}),
					]),
					dlocNotes: t.union([
						t.null,
						t.type({
							dlocKeyVersion: t.number,
							encryptedDlocNotes: tSodiumBytes,
						}),
					]),
					entryKey: t.union([
						t.null,
						t.type({
							dlocKeyVersion: t.number,
							encryptedKey: tSodiumBytes,
						}),
					]),
					locNotes: t.array(
						t.type({
							id: t.string,
							dlocKeyVersion: t.number,
							encryptedLocNotes: tSodiumBytes,
						}),
					),
				}),
			),
			contactInfoKeys: t.array(
				t.type({
					survivorId: t.string,
					dlocKeyVersion: t.number,
					encryptedKey: tSodiumBytes,
				}),
			),
		}),
		XXX_THINK_CAREFULLY_no_token: true,
	},

	SaveDataReencryptedByDloc: {
		input: t.type({
			dlocKeyVersion: t.number,
			entryData: t.array(
				t.type({
					entryId: t.string,
					encryptedAssignmentData: tSodiumBytes,
					encryptedQuestionnaire: t.union([tSodiumBytes, t.null]),
					encryptedDlocNotes: t.union([tSodiumBytes, t.null]),
					encryptedKey: t.union([tSodiumBytes, t.null]),
					locNotes: t.array(
						t.type({
							id: t.string,
							encryptedLocNotes: tSodiumBytes,
						}),
					),
				}),
			),
			contactInfoKeys: t.array(
				t.type({
					survivorId: t.string,
					encryptedKey: tSodiumBytes,
				}),
			),
		}),
		output: t.type({
			success: t.boolean,
		}),
	},

	SendDlocInvitation: {
		input: t.type({
			id: t.union([t.string, t.undefined]),
			data: t.union([
				t.type({
					name: t.string,
					email: t.string,
				}),
				t.null,
			]),
		}),
		output: t.type({
			success: t.boolean,
		}),
	},

	ValidateDlocAccountToken: {
		input: t.type({
			token: t.string,
		}),
		output: t.type({
			name: t.string,
			email: t.string,
		}),
	},

	GetDlocPublicKey: {
		input: t.type({
			dlocId: t.string,
		}),
		output: t.type({
			dlocPublicKey: tSodiumBytes,
		}),
		XXX_THINK_CAREFULLY_no_token: true,
	},

	SaveVersionedKeysForNewDloc: {
		input: t.type({
			dlocId: t.string,
			keys: t.array(
				t.type({
					version: t.number,
					key: tSodiumBytes,
				}),
			),
		}),
		output: t.type({
			success: t.boolean,
		}),
	},

	DlocGetKeysForEntriesForShareRecalculation: {
		input: t.type({
			locId: t.string,
		}),
		output: t.type({
			entryKeys: t.array(
				t.type({
					entryId: t.string,
					encryptedKey: tSodiumBytes,
					dlocKeyVersion: t.number,
				}),
			),
		}),
		XXX_THINK_CAREFULLY_no_token: true,
	},

	DlocSaveKeysForShareRecalculation: {
		input: t.type({
			locId: t.string,
			keys: t.array(
				t.type({
					entryId: t.string,
					encryptedKey: tSodiumBytes,
				}),
			),
		}),
		output: t.type({
			success: t.boolean,
		}),
	},

	DlocDeactivateLoc: {
		input: t.type({
			locId: t.string,
		}),
		output: t.type({
			success: t.boolean,
		}),
	},

	GetDeactivatedLocs: {
		input: t.unknown,
		output: t.array(
			t.type({
				id: t.string,
				name: t.string,
				email: t.string,
				practice: t.string,
				state: t.string,
				dateAdded: tSodiumDate,
				dateDeactivated: t.union([tSodiumDate, t.null]),
			}),
		),
		XXX_THINK_CAREFULLY_no_token: true,
	},
};

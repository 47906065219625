/* eslint-disable react/react-in-jsx-scope */
import { Fragment, h } from "preact";
import { route } from "preact-router";
import { useContext, useEffect, useState } from "preact/hooks";
import { DlocClientContext } from "../../components/DlocClientContext";
import { Toast } from "../../../../../../lib/components/toast";

export const DlocSignupToken = ({
	token,
}: {
	token?: string;
	path?: string;
}): JSX.Element => {
	const client = useContext(DlocClientContext);
	const [error, setError] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>(
		"There was an error verifying your invitation.",
	);

	useEffect(() => {
		const doLoad = async () => {
			try {
				const loader = document.getElementById("loaderWrapper");
				loader.style.display = "flex";
				const validationData = await client.validateAccountToken(token);
				client.contactName = validationData.name;
				client.contactEmail = validationData.email;
				client.token = token;
				route("/create-credentials");
			} catch (e) {
				setErrorMessage((e as Error).message);
				setError(true);
			}
		};

		void doLoad();
	}, []);

	return (
		<Fragment>
			{!error && (
				<div id="loaderWrapper">
					<div className="loaderContainer">
						<div className="loader" />
					</div>
				</div>
			)}

			{error && (
				<Toast
					error={true}
					message={errorMessage}
					closeFunc={async () => Promise.resolve()}
				/>
			)}
		</Fragment>
	);
};

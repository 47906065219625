/* eslint-disable react/react-in-jsx-scope */
import { h } from "preact";
import { Modal } from "../../../../../../lib/components/modal";

import "./styles.scss";
import { useLocs } from "../../dlocData";
import { useEffect, useState } from "preact/hooks";

export const LocPlusRequestModal = ({
	onClose,
	onRequest,
}: {
	onClose: () => void;
	onRequest: (
		newLocId: string,
		locPlusId: string,
		oldLocPlusId: string,
	) => void;
}): JSX.Element => {
	const { locs, unactivatedLocs, getCurrentLocPlus } = useLocs();
	const [locPlus, setLocPlus] = useState<string>("");
	const [newLoc, setNewLoc] = useState<string>("");
	const [originalLocPlus, setOriginalLocPlus] = useState<string>("");

	useEffect(() => {
		const getLocPlusForNewLoc = async () => {
			if (newLoc !== "") {
				const currentLocPlus = await getCurrentLocPlus(newLoc);
				setLocPlus(currentLocPlus);
				setOriginalLocPlus(currentLocPlus);
			}
		};

		void getLocPlusForNewLoc();
	}, [newLoc]);

	return (
		<Modal
			closeFunc={async () => {
				onClose();
				return Promise.resolve();
			}}
			canExit={true}
		>
			<div id="locPlusRequestModal">
				<h3>Resend LOC+ Activation Request</h3>
			</div>
			<div id="statusInputWrapper">
				<label htmlFor="newLocInput">Select New LOC</label>
				<select
					id="newLocInput"
					onChange={(e) => setNewLoc(e.currentTarget.value)}
				>
					<option disabled selected value="">
						[Select an LOC]
					</option>
					{unactivatedLocs.map((loc) => (
						<option key={loc.id} value={loc.id}>
							{loc.name}
						</option>
					))}
				</select>

				<label htmlFor="locPlusInput">Select LOC+</label>
				<select
					id="locPlusInput"
					onChange={(e) => setLocPlus(e.currentTarget.value)}
				>
					<option disabled selected={locPlus === ""} value="">
						[Select an LOC]
					</option>
					{locs.map((loc) => (
						<option key={loc.id} value={loc.id} selected={locPlus === loc.id}>
							{loc.name}
						</option>
					))}
				</select>
			</div>
			<div id="codesButtons" className="hideOnPrint topPadding">
				<a href="/dashboard">
					<button
						id="completeButton"
						className="secondaryButton"
						onClick={onClose}
					>
						Cancel
					</button>
					<button
						disabled={newLoc === "" || !locPlus || locPlus === ""}
						className={
							newLoc !== "" && locPlus !== ""
								? "primaryButton"
								: "secondaryButton"
						}
						onClick={() => onRequest(newLoc, locPlus, originalLocPlus)}
					>
						Save changes
					</button>
				</a>
			</div>
		</Modal>
	);
};

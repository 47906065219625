/* eslint-disable react/react-in-jsx-scope */
import { h } from "preact";
import { dlocEntry } from "../../../../../../lib/data";
import { useLocs } from "../../dlocData";
import { Modal } from "../../../../../../lib/components/modal";

// This flag is needed because ViewLocModal gets called multiple times when "View LOC" is selected.
// I'm not sure why this happens, but it results in multiple Honeycomb events being sent
let viewLocEventSent = false;

export const ViewLocModal = ({
	entry,
	onClose,
}: {
	entry: dlocEntry;
	onClose: () => void;
}): JSX.Element => {
	const { locs, viewLoc } = useLocs();

	const activeLOC = locs.find((v) => v.id === entry.assignedLOC.id);
	if (activeLOC && !viewLocEventSent) {
		viewLoc(activeLOC.id);
		viewLocEventSent = true;
	}

	return (
		// eslint-disable-next-line @typescript-eslint/require-await
		<Modal closeFunc={async () => onClose()} canExit={true}>
			{activeLOC && (
				<div id="locInfo">
					<h3>Legal Options Counselor Profile</h3>
					<p className="bold">
						<b>Legal Options Counselor’s Name</b>
					</p>
					<p>{activeLOC.name} </p>
					<p className="bold">
						<b>Email</b>
					</p>
					<p>{activeLOC.email} </p>
					<p className="bold">
						<b>Practice Name</b>
					</p>
					<p>{activeLOC.practice} </p>
					<p className="bold">
						<b>State(s) of practice</b>
					</p>
					<p>{activeLOC.state}</p>
				</div>
			)}
		</Modal>
	);
};

/* eslint-disable react/react-in-jsx-scope */
import { Fragment, h } from "preact";
import { useContext, useEffect, useRef, useState } from "preact/hooks";

import {
	accountRecoveryIsSetUp,
	CaseStatus as DataCaseStatus,
	CompareFunction,
	dlocEntry,
	DlocFilters,
	formatDate,
	formatStatus,
} from "../../../../../../lib/data";
import { CaseStatus } from "../../../../../../lib/components/case-status";
import { useCases, useLocs } from "../../dlocData";
import { NewLocData } from "../../../../client";
import { Toast } from "../../../../../../lib/components/toast";
import {
	AssignClearModal,
	InviteModal,
	LocPlusRequestModal,
	MarkCaseFalseMatchModal,
	SelectLocModal,
	ViewLocModal,
	ViewQuestionnaireModal,
} from "../../components/modals";

import "./styles.scss";
import { SortableHeader } from "../../../../../../lib/components/SortableHeader";
import { ViewEditNotes } from "../../components/ViewEditNotes";
import { StringFilter } from "../../../../../../lib/components/Filter/StringFilter";
import { StringArrayFilter } from "../../../../../../lib/components/Filter/StringArrayFilter";
import { DlocClientContext } from "../../components/DlocClientContext";
import { route } from "preact-router";
import Tooltip from "../../../../../../lib/components/tooltip/Tooltip";

interface SortState {
	sortAsc: {
		id: boolean;
		status: boolean;
		matchDate: boolean;
		survivor: boolean;
		perpetrator: boolean;
		assignedLoc: boolean;
		assignedDate: boolean;
	};
	header: string;
	comparer: CompareFunction;
}

const defaultFilters: DlocFilters = {
	caseTypes: "all",
	ids: [],
	statuses: [],
	matchDates: [],
	survivors: [],
	perpetrators: [],
	incidentStates: [],
	campuses: [],
	assignedLocs: [],
	assignedDates: [],
	custom: {
		ids: undefined,
		statuses: undefined,
		matchDates: undefined,
		survivors: undefined,
		perpetrators: undefined,
		incidentStates: undefined,
		campuses: undefined,
		assignedLocs: undefined,
		assignedDates: undefined,
	},
};

const tableHeaders: Record<string, string> = {
	id: "ID",
	status: "CASE STATUS",
	matchDate: "MATCH DATE",
	survivor: "SURVIVOR",
	perpetrator: "PERPETRATOR",
	state: "STATE OF INCIDENT",
	campus: "COLLEGE",
	assignedLoc: "ASSIGNED TO",
	assignedDate: "DATE ASSIGNED",
	preferredLanguage: "PREFERRED LANGUAGE",
};

const stringCompare = (a: string, b: string) => {
	if ((!a && b) || a < b) {
		return -1;
	}

	if ((a && !b) || a > b) {
		return 1;
	}

	return 0;
};

const dateCompare = (a: Date, b: Date) => {
	if (a && !b) {
		return -1;
	}

	if (!a && b) {
		return 1;
	}

	if (!a && !b) {
		return 0;
	}

	return a.getTime() - b.getTime();
};

const setUpCompareFunction =
	(
		ascending: boolean,
		comparer:
			| ((a: string, b: string) => number)
			| ((a: Date, b: Date) => number),
		dlocEntryProperty: string,
	) =>
	(a: dlocEntry, b: dlocEntry) =>
		ascending
			? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				comparer(a[dlocEntryProperty], b[dlocEntryProperty])
			: -1 * comparer(a[dlocEntryProperty], b[dlocEntryProperty]);

const compareFunctions: Record<string, CompareFunction> = {};

// eslint-disable-next-line no-empty-pattern
export const DashboardPage = ({}: { path?: string }): h.JSX.Element => {
	const dlocClient = useContext(DlocClientContext);
	const [showAssignForRewrite, setShowAssignForRewrite] =
		useState<boolean>(false);
	const [showAssign, setShowAssign] = useState<dlocEntry | null>(null);
	const [showClearAssign, setClearAssign] = useState<dlocEntry | null>(null);
	const [showViewLoc, setShowViewLoc] = useState<dlocEntry | null>(null);
	const [showViewQuestionnaire, setShowViewQuestionnaire] =
		useState<dlocEntry | null>(null);
	const [showInviteNewLoc, setShowInviteNewLoc] = useState<boolean>(false);
	const [newLocInvitationEmailSent, setShowNewLocInvitationEmailSent] =
		useState<boolean>(false);
	const [isErrorState, setIsErrorState] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [showNewLocManagement, setShowNewLocManagement] =
		useState<boolean>(false);
	const [showResendLocPlusActivation, setShowResendLocPlusActivation] =
		useState<boolean>(false);
	const [showResendLocInvitation, setShowResendLocInvitation] =
		useState<boolean>(false);
	const [caseToMarkAsFalseMatch, setCaseToMarkAsFalseMatch] =
		useState<dlocEntry>(null);
	const {
		cases,
		assignCase,
		clearAssignCase,
		casesLoaded,
		sort,
		updateCaseStatus,
		saveNotes,
	} = useCases();
	const {
		inviteNewLoc,
		sendLocPlusRequest,
		reinviteNewLoc,
		locs,
		unregisteredLocs,
		assignLocStar,
		assignKeysForShareRegeneration,
	} = useLocs();
	const [sortState, setSortState] = useState<SortState>({
		sortAsc: {
			id: false,
			status: false,
			matchDate: false,
			survivor: false,
			perpetrator: false,
			assignedLoc: false,
			assignedDate: false,
		},
		header: tableHeaders.matchDate,
		comparer: (a, b) => -1 * dateCompare(a.matchFound, b.matchFound),
	});
	const [rerender, setRerender] = useState<number>(0);
	const [showChooseLocStar, setShowChooseLocStar] = useState<boolean>(false);
	const [showEditNotes, setShowEditNotes] = useState<dlocEntry>(null);
	const [showFilters, setShowFilters] = useState<boolean>(false);
	const [displayCases, setDisplayCases] = useState<dlocEntry[]>([...cases]);
	const [filters, setFilters] = useState<DlocFilters>(defaultFilters);
	const [closedStatuses] = useState<string[]>([]);
	const [nonClosedStatuses] = useState<string[]>([]);
	const [ids, setIds] = useState<string[]>([]);
	const [statuses, setStatuses] = useState<string[]>([]);
	const [matchDates, setMatchDates] = useState<string[]>([]);
	const [survivors, setSurvivors] = useState<string[]>([]);
	const [perpetrators, setPerpetrators] = useState<string[]>([]);
	const [incidentStates, setIncidentStates] = useState<string[]>([]);
	const [campuses, setCampuses] = useState<string[]>([]);
	const [assignedLocs, setAssignedLocs] = useState<string[]>([]);
	const [assignedDates, setAssignedDates] = useState<string[]>([]);
	const formRef = useRef<HTMLFormElement>(null);
	const [idCopied, setIdCopied] = useState<boolean>(false);

	useEffect(() => {
		if (!accountRecoveryIsSetUp(dlocClient)) {
			route("/setup-recovery");
		}
	}, [dlocClient.envVars]);

	useEffect(() => {
		for (const status in DataCaseStatus) {
			if (status.includes("CLOSED")) {
				closedStatuses.push(status);
			} else if (
				!status.startsWith("DELETED") &&
				!status.includes("FALSE_MATCH")
			) {
				nonClosedStatuses.push(status);
			}
		}

		compareFunctions[tableHeaders.id] = setUpCompareFunction(
			true,
			stringCompare,
			"id",
		);
		compareFunctions[tableHeaders.status] = setUpCompareFunction(
			true,
			stringCompare,
			"status",
		);
		compareFunctions[tableHeaders.matchDate] = setUpCompareFunction(
			true,
			dateCompare,
			"matchFound",
		);
		compareFunctions[tableHeaders.survivor] = setUpCompareFunction(
			true,
			stringCompare,
			"userID",
		);
		compareFunctions[tableHeaders.perpetrator] = setUpCompareFunction(
			true,
			stringCompare,
			"perpetratorId",
		);
		compareFunctions[tableHeaders.assignedLoc] = (a: dlocEntry, b: dlocEntry) =>
			stringCompare(a.assignedLOC?.name, b.assignedLOC?.name);
		compareFunctions[tableHeaders.assignedDate] = setUpCompareFunction(
			true,
			dateCompare,
			"dateAssigned",
		);

		if (window.localStorage.getItem("sortState")) {
			setSortState(
				JSON.parse(window.localStorage.getItem("sortState")) as SortState,
			);
		}

		if (window.localStorage.getItem("filters")) {
			setFilters(
				JSON.parse(window.localStorage.getItem("filters")) as DlocFilters,
			);
		}
	}, []);

	useEffect(() => {
		if (!sortState.comparer) {
			let sortAsc = false;
			for (const headerKey of Object.keys(tableHeaders)) {
				if (sortState.header === tableHeaders[headerKey]) {
					sortAsc = sortState.sortAsc[headerKey] as boolean;
				}
			}
			sortState.comparer = (a, b) =>
				sortAsc
					? compareFunctions[sortState.header](a, b)
					: -1 * compareFunctions[sortState.header](a, b);
		}

		window.localStorage.setItem("sortState", JSON.stringify(sortState));
		forceRerender();
	}, [sortState]);

	useEffect(() => {
		window.localStorage.setItem("filters", JSON.stringify(filters));
	});

	useEffect(() => {
		const tempIds: Set<string> = new Set<string>();
		const tempStatuses: Set<string> = new Set<string>();
		const tempMatchDates: Set<string> = new Set<string>();
		const tempSurvivors: Set<string> = new Set<string>();
		const tempPerpetrators: Set<string> = new Set<string>();
		const tempIncidentStates: Set<string> = new Set<string>();
		const tempCampuses: Set<string> = new Set<string>();
		const tempAssignedLocs: Set<string> = new Set<string>();
		const tempAssignedDates: Set<string> = new Set<string>();

		for (const entry of cases) {
			tempIds.add(entry.id);
			tempStatuses.add(entry.status);
			tempMatchDates.add(formatDate(entry.matchFound));
			tempSurvivors.add(entry.userID);
			tempPerpetrators.add(entry.perpetratorId);
			tempIncidentStates.add(entry.incidentState);
			tempCampuses.add(entry.userCampusAtCreation);
			tempAssignedLocs.add(entry.assignedLOC?.name ?? "\tUnassigned");
			tempAssignedDates.add(formatDate(entry.dateAssigned) ?? "Unassigned");
		}

		setIds([...tempIds].sort());
		setStatuses([...tempStatuses].sort());
		setMatchDates([...tempMatchDates].sort().reverse());
		setSurvivors([...tempSurvivors].sort());
		setPerpetrators([...tempPerpetrators].sort());
		setIncidentStates([...tempIncidentStates].sort());
		setCampuses([...tempCampuses].sort());
		setAssignedLocs([...tempAssignedLocs].sort());
		setAssignedDates([...tempAssignedDates].sort().reverse());

		setDisplayCases([...cases]);

		if (sortState.comparer) {
			sort(sortState.comparer);
		}
	}, [casesLoaded, rerender]);

	useEffect(() => {
		let casesToDisplay = [...cases];
		switch (filters.caseTypes) {
			case "closed":
				casesToDisplay = casesToDisplay.filter((entry) =>
					closedStatuses.includes(entry.status),
				);
				break;
			case "open":
				casesToDisplay = casesToDisplay.filter((entry) =>
					nonClosedStatuses.includes(entry.status),
				);
				break;
			case "deleted":
				casesToDisplay = casesToDisplay.filter((entry) =>
					entry.status.startsWith("DELETED"),
				);
				break;
			case "false":
				casesToDisplay = casesToDisplay.filter((entry) =>
					entry.status.includes("FALSE_MATCH"),
				);
		}

		if (filters.custom.ids) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				entry.id.includes(filters.custom.ids),
			);
		}
		if (filters.ids.length > 0) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				filters.ids.includes(entry.id),
			);
		}

		if (filters.custom.statuses) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				formatStatus(entry.status).includes(filters.custom.statuses),
			);
		}
		if (filters.statuses.length > 0) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				filters.statuses.includes(entry.status),
			);
		}

		if (filters.custom.matchDates) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				formatDate(entry.matchFound).includes(
					filters.custom.matchDates.replace(/-/g, "\u2011"),
				),
			);
		}
		if (filters.matchDates.length > 0) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				filters.matchDates.includes(formatDate(entry.matchFound)),
			);
		}

		if (filters.custom.survivors) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				entry.userID.includes(filters.custom.survivors),
			);
		}
		if (filters.survivors.length > 0) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				filters.survivors.includes(entry.userID),
			);
		}

		if (filters.custom.perpetrators) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				entry.perpetratorId.includes(filters.custom.perpetrators),
			);
		}
		if (filters.perpetrators.length > 0) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				filters.perpetrators.includes(entry.perpetratorId),
			);
		}

		if (filters.custom.incidentStates) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				entry.incidentState.includes(filters.custom.incidentStates),
			);
		}
		if (filters.incidentStates.length > 0) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				filters.incidentStates.includes(entry.incidentState),
			);
		}

		if (filters.custom.campuses) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				entry.userCampusAtCreation.includes(filters.custom.campuses),
			);
		}
		if (filters.campuses.length > 0) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				filters.campuses.includes(entry.userCampusAtCreation),
			);
		}

		if (filters.custom.assignedLocs) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				entry.assignedLOC?.name.includes(filters.custom.assignedLocs),
			);
		}
		if (filters.assignedLocs.length > 0) {
			casesToDisplay = casesToDisplay.filter(
				(entry) =>
					filters.assignedLocs.includes(entry.assignedLOC?.name) ||
					(filters.assignedLocs.includes("\tUnassigned") && !entry.assignedLOC),
			);
		}

		if (filters.custom.assignedDates) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				formatDate(entry.dateAssigned).includes(
					filters.custom.assignedDates.replace(/-/g, "\u2011"),
				),
			);
		}
		if (filters.assignedDates.length > 0) {
			casesToDisplay = casesToDisplay.filter(
				(entry) =>
					filters.assignedDates.includes(formatDate(entry.dateAssigned)) ||
					(filters.assignedDates.includes("Unassigned") && !entry.dateAssigned),
			);
		}

		setDisplayCases(casesToDisplay);
	}, [filters, rerender, casesLoaded]);

	const setCustomStringFilter = (filter: string, value: string) => {
		if (value) {
			setFilters({
				...filters,
				custom: {
					...filters.custom,
					[filter]: value,
				},
			});
		} else {
			setFilters({
				...filters,
				custom: {
					...filters.custom,
					[filter]: undefined,
				},
			});
		}
	};

	const setStringArrayFilter = (filter: string, value: string[]) => {
		if (!value || value.length === 0) {
			setFilters({
				...filters,
				[filter]: [],
			});
		} else {
			setFilters({
				...filters,
				[filter]: value,
			});
		}
	};

	const setStringFilter = (filter: string, value: string) => {
		setFilters({
			...filters,
			[filter]: value,
		});
	};

	const forceRerender = () => {
		setRerender(rerender + 1);
	};

	const handleInvite = async (formData: NewLocData) => {
		try {
			const response = await inviteNewLoc(formData);
			setShowNewLocInvitationEmailSent(response);
			setIsErrorState(!response);
			if (!response) {
				setErrorMessage("Error sending invitation");
			}
		} catch (error) {
			setIsErrorState(true);
			setErrorMessage((error as Error).message);
		} finally {
			setShowInviteNewLoc(false);
		}
	};

	const handleLocPlusRequest = async (newLocId: string, locPlusId: string) => {
		try {
			await sendLocPlusRequest(newLocId, locPlusId);
		} catch (error) {
			setIsErrorState(true);
			setErrorMessage((error as Error).message);
		} finally {
			setShowResendLocPlusActivation(false);
		}
	};

	const handleReinviteLoc = async (locId: string) => {
		try {
			await reinviteNewLoc(locId);
		} catch (error) {
			setIsErrorState(true);
			setErrorMessage((error as Error).message);
		} finally {
			setShowResendLocInvitation(false);
		}
	};

	const handleAssign = (entry: dlocEntry, locID: string) => {
		const modalLoader = document.getElementById("modalLoader");
		modalLoader.style.display = "flex";

		assignCase(entry, locID);

		modalLoader.style.display = "none";
		setShowAssign(null);
	};

	const handleChooseLocStar = async (locId: string) => {
		await assignLocStar(locId);
		setShowChooseLocStar(false);
	};

	const handleAssignForRewrite = async (locId: string) => {
		const modalLoader = document.getElementById("modalLoader");
		modalLoader.style.display = "flex";

		await assignKeysForShareRegeneration(locId);

		modalLoader.style.display = "none";
		setShowAssignForRewrite(false);
	};

	const handleClearAssign = (entry: dlocEntry) => {
		// Check for other cases assigned to the same LOC for the same survivor.
		// (If there are any then we don't want to delete the contact info key
		// for this LOC/Survivor combination.)
		const relevantCases = cases.filter(
			(current) =>
				current.id !== entry.id &&
				current.assignedLOC?.id === entry.assignedLOC.id &&
				current.userID === entry.userID,
		);

		if (relevantCases.length === 0) {
			clearAssignCase(entry, entry.assignedLOC.id, entry.userID);
		} else {
			clearAssignCase(entry, entry.assignedLOC.id, null);
		}

		setClearAssign(null);
	};

	const handleStatusUpdate = async (
		entry: dlocEntry,
		status: DataCaseStatus,
	) => {
		try {
			await updateCaseStatus(entry, status);
			entry.status = status;
			forceRerender();
		} catch (error) {
			setErrorMessage((error as Error).message);
			setIsErrorState(true);
		}
	};

	const handleMarkCaseAsFalseMatch = async (reason: string) => {
		if (!caseToMarkAsFalseMatch) {
			setErrorMessage("No case selected");
			setIsErrorState(true);
			return;
		}

		const entry = caseToMarkAsFalseMatch;
		const notesText = entry.notes ?? "";
		let updatedNotes = notesText;

		if (notesText.length > 0) {
			updatedNotes += "\n\n";
		}

		updatedNotes +=
			`${new Date().toLocaleString()} (${Intl.DateTimeFormat().resolvedOptions().timeZone})` +
			": Case marked as false match\n";
		updatedNotes += `Previous status: ${formatStatus(entry.status)}\n`;
		updatedNotes += `Reason for marking as a false match: ${reason}`;

		try {
			await saveNotes(entry.id, updatedNotes);
			entry.notes = updatedNotes;

			await updateCaseStatus(entry, DataCaseStatus.FALSE_MATCH);
			entry.status = DataCaseStatus.FALSE_MATCH as DataCaseStatus;
		} catch (error) {
			setErrorMessage((error as Error).message);
			setIsErrorState(true);
		}

		setCaseToMarkAsFalseMatch(null);
	};

	return (
		<Fragment>
			<main id="dashboard">
				<div id="dashCard">
					<div id="topButtons">
						<button
							type="button"
							className="primaryButton"
							onClick={() => {
								setShowChooseLocStar(true);
							}}
						>
							Select LOC* to Generate New Key
						</button>
						<div className="newLocManagement">
							<button
								type="button"
								className="primaryButton rightAligned"
								onClick={() => setShowNewLocManagement(!showNewLocManagement)}
							>
								Manage New LOC Invitations
							</button>
							{showNewLocManagement && (
								<div className="newLocManagementButtons">
									<button
										type="button"
										className="secondaryButton"
										onClick={() => {
											setShowNewLocManagement(false);
											setShowInviteNewLoc(true);
										}}
									>
										Add a New LOC
									</button>
									<button
										type="button"
										className="secondaryButton"
										onClick={() => {
											setShowNewLocManagement(false);
											setShowResendLocInvitation(true);
										}}
									>
										Resend Invitation to New LOC
									</button>
									<button
										type="button"
										className="secondaryButton"
										onClick={() => {
											setShowNewLocManagement(false);
											setShowResendLocPlusActivation(true);
										}}
									>
										Resend Request to LOC+
									</button>
								</div>
							)}
						</div>
						<button
							className="iconButton filterButton"
							onClick={() => {
								setShowFilters(!showFilters);
							}}
						>
							<img
								src={
									require("../../../../../../lib/assets/icons/sliders.svg") as string
								}
								width="15"
								alt="toggle view filters"
							/>{" "}
							{showFilters ? "Hide" : "Show"} Filters
						</button>
					</div>

					{showFilters && (
						<div id="filters">
							<button
								className="iconButton"
								onClick={() => {
									setFilters(defaultFilters);
									formRef.current.reset();
								}}
							>
								<img
									src={
										require("../../../../../../lib/assets/icons/ban.svg") as string
									}
									width="12"
									alt="clear all filters"
								/>{" "}
								Clear all filters
							</button>

							<br />

							<form id="filters" ref={formRef}>
								<StringFilter
									filter="caseTypes"
									label="Case types"
									filters={filters}
									onChange={setStringFilter}
									values={[
										{ value: "all", displayValue: "Show all cases" },
										{ value: "closed", displayValue: "Show only closed cases" },
										{ value: "open", displayValue: "Show only open cases" },
										{
											value: "deleted",
											displayValue: "Show only deleted cases",
										},
										{ value: "false", displayValue: "Show only false matches" },
									]}
								/>

								<br />

								<StringArrayFilter
									filter="ids"
									label="Case ID"
									filters={filters}
									onChange={setStringArrayFilter}
									onUpdateCustom={setCustomStringFilter}
									values={ids.map((id) => ({ value: id }))}
								/>

								<StringArrayFilter
									filter="statuses"
									label="Status"
									filters={filters}
									onChange={setStringArrayFilter}
									onUpdateCustom={setCustomStringFilter}
									values={statuses.map((s) => ({
										value: s,
										displayValue: formatStatus(s),
									}))}
								/>

								<StringArrayFilter
									filter="matchDates"
									label="Match date"
									filters={filters}
									onChange={setStringArrayFilter}
									onUpdateCustom={setCustomStringFilter}
									values={matchDates.map((md) => ({ value: md }))}
								/>

								<StringArrayFilter
									filter="survivors"
									label="Survivor ID"
									filters={filters}
									onChange={setStringArrayFilter}
									onUpdateCustom={setCustomStringFilter}
									values={survivors.map((id) => ({ value: id }))}
								/>

								<StringArrayFilter
									filter="perpetrators"
									label="Perpetrator ID"
									filters={filters}
									onChange={setStringArrayFilter}
									onUpdateCustom={setCustomStringFilter}
									values={perpetrators.map((id) => ({ value: id }))}
								/>

								<StringArrayFilter
									filter="incidentStates"
									label="State"
									filters={filters}
									onChange={setStringArrayFilter}
									onUpdateCustom={setCustomStringFilter}
									values={incidentStates.map((state) => ({ value: state }))}
								/>

								<StringArrayFilter
									filter="campuses"
									label="College"
									filters={filters}
									onChange={setStringArrayFilter}
									onUpdateCustom={setCustomStringFilter}
									values={campuses.map((p) => ({ value: p }))}
								/>

								<StringArrayFilter
									filter="assignedLocs"
									label="Assigned LOC"
									filters={filters}
									onChange={setStringArrayFilter}
									onUpdateCustom={setCustomStringFilter}
									values={assignedLocs.map((locName) => ({ value: locName }))}
								/>

								<StringArrayFilter
									filter="assignedDates"
									label="Assigned date"
									filters={filters}
									onChange={setStringArrayFilter}
									onUpdateCustom={setCustomStringFilter}
									values={assignedDates.map((ad) => ({ value: ad }))}
								/>
							</form>
						</div>
					)}
					<div id="dashboardTable">
						<table>
							<thead>
								<tr id="dashHeader">
									<SortableHeader
										compare={(a, b) => stringCompare(a.id, b.id)}
										doSort={sortState.header === tableHeaders.id}
										onClick={() => {
											setSortState({
												sortAsc: {
													...sortState.sortAsc,
													id: !sortState.sortAsc.id,
												},
												header: tableHeaders.id,
												comparer: setUpCompareFunction(
													!sortState.sortAsc.id,
													stringCompare,
													"id",
												),
											});
										}}
										onSort={forceRerender}
										sort={sort}
										sortAsc={sortState.sortAsc.id}
									>
										{tableHeaders.id}
									</SortableHeader>
									<SortableHeader
										doSort={sortState.header === tableHeaders.status}
										compare={(a, b) => stringCompare(a.status, b.status)}
										onSort={forceRerender}
										onClick={() => {
											setSortState({
												sortAsc: {
													...sortState.sortAsc,
													status: !sortState.sortAsc.status,
												},
												header: tableHeaders.status,
												comparer: setUpCompareFunction(
													!sortState.sortAsc.status,
													stringCompare,
													"status",
												),
											});
										}}
										sort={sort}
										sortAsc={sortState.sortAsc.status}
									>
										{tableHeaders.status}
									</SortableHeader>
									<SortableHeader
										doSort={sortState.header === tableHeaders.matchDate}
										compare={(a, b) => dateCompare(a.matchFound, b.matchFound)}
										onSort={forceRerender}
										onClick={() => {
											setSortState({
												sortAsc: {
													...sortState.sortAsc,
													matchDate: !sortState.sortAsc.matchDate,
												},
												header: tableHeaders.matchDate,
												comparer: setUpCompareFunction(
													!sortState.sortAsc.matchDate,
													dateCompare,
													"matchFound",
												),
											});
										}}
										sort={sort}
										sortAsc={sortState.sortAsc.matchDate}
									>
										{tableHeaders.matchDate}
									</SortableHeader>
									<SortableHeader
										doSort={sortState.header === tableHeaders.survivor}
										compare={(a, b) => stringCompare(a.userID, b.userID)}
										onSort={forceRerender}
										onClick={() => {
											setSortState({
												sortAsc: {
													...sortState.sortAsc,
													survivor: !sortState.sortAsc.survivor,
												},
												header: tableHeaders.survivor,
												comparer: setUpCompareFunction(
													!sortState.sortAsc.survivor,
													stringCompare,
													"userID",
												),
											});
										}}
										sort={sort}
										sortAsc={sortState.sortAsc.survivor}
									>
										{tableHeaders.survivor}
									</SortableHeader>
									<SortableHeader
										doSort={sortState.header === tableHeaders.perpetrator}
										compare={(a, b) =>
											stringCompare(a.perpetratorId, b.perpetratorId)
										}
										onSort={forceRerender}
										onClick={() => {
											setSortState({
												sortAsc: {
													...sortState.sortAsc,
													perpetrator: !sortState.sortAsc.perpetrator,
												},
												header: tableHeaders.perpetrator,
												comparer: setUpCompareFunction(
													!sortState.sortAsc.perpetrator,
													stringCompare,
													"perpetratorId",
												),
											});
										}}
										sort={sort}
										sortAsc={sortState.sortAsc.perpetrator}
									>
										{tableHeaders.perpetrator}
									</SortableHeader>
									<th>{tableHeaders.state}</th>
									<th>{tableHeaders.campus}</th>
									<th>{tableHeaders.preferredLanguage}</th>
									<SortableHeader
										doSort={sortState.header === tableHeaders.assignedLoc}
										compare={(a, b) =>
											stringCompare(a.assignedLOC?.name, b.assignedLOC?.name)
										}
										onSort={forceRerender}
										onClick={() => {
											setSortState({
												sortAsc: {
													...sortState.sortAsc,
													assignedLoc: !sortState.sortAsc.assignedLoc,
												},
												header: tableHeaders.assignedLoc,
												comparer: (a: dlocEntry, b: dlocEntry) =>
													!sortState.sortAsc.assignedLoc
														? stringCompare(
																a.assignedLOC?.name,
																b.assignedLOC?.name,
															)
														: -1 *
															stringCompare(
																a.assignedLOC?.name,
																b.assignedLOC?.name,
															),
											});
										}}
										sort={sort}
										sortAsc={sortState.sortAsc.assignedLoc}
									>
										{tableHeaders.assignedLoc}
									</SortableHeader>
									<SortableHeader
										doSort={sortState.header === tableHeaders.assignedDate}
										compare={(a, b) =>
											dateCompare(a.dateAssigned, b.dateAssigned)
										}
										onSort={forceRerender}
										onClick={() => {
											setSortState({
												sortAsc: {
													...sortState.sortAsc,
													assignedDate: !sortState.sortAsc.assignedDate,
												},
												header: tableHeaders.assignedDate,
												comparer: setUpCompareFunction(
													!sortState.sortAsc.assignedDate,
													dateCompare,
													"dateAssigned",
												),
											});
										}}
										sort={sort}
										sortAsc={sortState.sortAsc.assignedDate}
									>
										{tableHeaders.assignedDate}
									</SortableHeader>
									<th />
								</tr>
							</thead>
							<tbody>
								{!casesLoaded && (
									<tr>
										<td colSpan={11}>
											<div className="smallLoader centered" />
										</td>
									</tr>
								)}
								{casesLoaded &&
									displayCases.map((entry) => (
										<tr
											className={
												entry.survivorHasManyEntries
													? "record flagged"
													: "record"
											}
											key={entry.id}
										>
											<td className="id">
												<Tooltip content={entry.id} direction="right">
													{entry.id.substring(0, 8)}
												</Tooltip>
												<button
													className="iconButton copyButton"
													onClick={async () => {
														await navigator.clipboard.writeText(entry.id);
														setIdCopied(true);
													}}
												>
													<img
														src={
															require("../../../../../../lib/assets/icons/copy-sharp-light.svg") as string
														}
														width="15"
														alt="copy id to clipboard"
													/>
												</button>
											</td>
											<td>
												<CaseStatus
													status={entry.status}
													lastUpdated={entry.statusChanged}
												/>
											</td>
											<td>{formatDate(entry.matchFound)}</td>
											<td className="id">
												<Tooltip content={entry.userID}>
													{entry.userID.substring(0, 8)}
												</Tooltip>
												<button
													className="iconButton copyButton"
													onClick={async () => {
														await navigator.clipboard.writeText(entry.userID);
														setIdCopied(true);
													}}
												>
													<img
														src={
															require("../../../../../../lib/assets/icons/copy-sharp-light.svg") as string
														}
														width="15"
														alt="copy id to clipboard"
													/>
												</button>
											</td>
											<td className="id">
												<Tooltip content={entry.perpetratorId}>
													{entry.perpetratorId.substring(0, 8)}
												</Tooltip>
												<button
													className="iconButton perpCopyButton"
													onClick={async () => {
														await navigator.clipboard.writeText(
															entry.perpetratorId,
														);
														setIdCopied(true);
													}}
												>
													<img
														src={
															require("../../../../../../lib/assets/icons/copy-sharp-light.svg") as string
														}
														width="15"
														alt="copy id to clipboard"
													/>
												</button>
											</td>
											<td>{entry.incidentState}</td>
											<td>{entry.userCampusAtCreation}</td>
											<td
												className={
													entry.accommodationsNeeded ? "highlighted" : ""
												}
											>
												{entry.preferredLanguage
													? entry.preferredLanguage
													: "Not specified"}
											</td>
											<td>{entry.assignedLOC ? entry.assignedLOC.name : ""}</td>
											<td>
												{entry.assignedLOC && (
													<span>{formatDate(entry.dateAssigned)}</span>
												)}
											</td>
											<td className="moreIcon">
												<img
													src={require("../../assets/more.svg") as string}
													alt="more icon"
												/>
												<div className="moreDropdown">
													{entry.status.toString().includes("CLOSED") && (
														<button
															onClick={async () => {
																await handleStatusUpdate(
																	entry,
																	DataCaseStatus.REOPENED_CASE,
																);
															}}
														>
															Reopen Case
														</button>
													)}
													<button
														onClick={() => {
															route(`case/${entry.id}`);
														}}
													>
														Case Details
													</button>
													{entry.assignedLOC !== null && (
														<Fragment>
															<button onClick={() => setShowViewLoc(entry)}>
																View LOC
															</button>
															<button onClick={() => setClearAssign(entry)}>
																Deassign Case
															</button>
														</Fragment>
													)}
													{!entry.status.startsWith("DELETED") &&
														entry.assignedLOC === null && (
															<button onClick={() => setShowAssign(entry)}>
																Assign Case
															</button>
														)}
													{(entry.status ===
														DataCaseStatus.CLOSED_COORDINATING_ACTION ||
														entry.status ===
															DataCaseStatus.CLOSED_NOT_COORDINATING ||
														entry.status ===
															DataCaseStatus.CLOSED_DO_NOT_CONTACT) &&
														entry.closeOutQuestionnaire && (
															<Fragment>
																<button
																	onClick={() =>
																		setShowViewQuestionnaire(entry)
																	}
																>
																	View Questionnaire
																</button>
															</Fragment>
														)}
													{entry.status ===
														DataCaseStatus.NO_CONTACT_ATTEMPTED &&
														entry.assignmentHistory.length === 0 && (
															<button
																onClick={async () => {
																	await handleStatusUpdate(
																		entry,
																		DataCaseStatus.NEW_CASE,
																	);
																}}
															>
																Mark as New
															</button>
														)}
													{entry.status === DataCaseStatus.NEW_CASE && (
														<button
															onClick={async () => {
																await handleStatusUpdate(
																	entry,
																	DataCaseStatus.NO_CONTACT_ATTEMPTED,
																);
															}}
														>
															Mark as Not New
														</button>
													)}
													{!entry.status.startsWith("DELETED") &&
														!entry.status.includes("FALSE_MATCH") && (
															<button
																onClick={() => {
																	setCaseToMarkAsFalseMatch(entry);
																}}
															>
																Mark as False Match
															</button>
														)}
													<button
														onClick={() => {
															setShowEditNotes(entry);
														}}
													>
														View/Edit Notes
													</button>
												</div>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
					{dlocClient.envVars.UI_ENV_REWRITE_NEEDED === "true" && (
						<button
							className="secondaryButton"
							onClick={() => setShowAssignForRewrite(true)}
						>
							Assign entries for rewrite
						</button>
					)}
				</div>
			</main>

			{showInviteNewLoc && (
				<InviteModal
					onClose={() => setShowInviteNewLoc(false)}
					onInvite={(formData) => handleInvite(formData)}
				/>
			)}

			{showResendLocPlusActivation && (
				<LocPlusRequestModal
					onClose={() => setShowResendLocPlusActivation(false)}
					onRequest={handleLocPlusRequest}
				/>
			)}

			{showResendLocInvitation && (
				<SelectLocModal
					onClose={() => setShowResendLocInvitation(false)}
					onSelect={(locId) => {
						void handleReinviteLoc(locId);
					}}
					title="Select LOC to Resend Invitation To"
					locs={unregisteredLocs}
				/>
			)}

			{showAssignForRewrite && (
				<SelectLocModal
					onClose={() => {
						setShowAssignForRewrite(false);
					}}
					onSelect={async (id: string) => {
						await handleAssignForRewrite(id);
					}}
					title="Select LOC to rewrite shares"
					locs={locs}
				/>
			)}

			{showClearAssign && (
				<AssignClearModal
					onClose={() => setClearAssign(null)}
					onAssign={() => {
						handleClearAssign(showClearAssign);
					}}
				/>
			)}
			{showAssign && (
				<SelectLocModal
					onClose={() => setShowAssign(null)}
					onSelect={(id) => {
						handleAssign(showAssign, id);
					}}
					title="Assign to Legal Options Counselor"
					locs={locs}
				/>
			)}
			{showChooseLocStar && (
				<SelectLocModal
					onClose={() => {
						setShowChooseLocStar(false);
					}}
					onSelect={async (id) => {
						await handleChooseLocStar(id);
					}}
					title="Select LOC*"
					locs={locs}
				/>
			)}
			{showViewLoc && (
				<ViewLocModal
					entry={showViewLoc}
					onClose={() => {
						setShowViewLoc(null);
					}}
				/>
			)}
			{showViewQuestionnaire && (
				<ViewQuestionnaireModal
					entry={showViewQuestionnaire}
					onClose={() => setShowViewQuestionnaire(null)}
				/>
			)}
			{showEditNotes && (
				<ViewEditNotes
					onClose={() => {
						setShowEditNotes(null);
					}}
					onSave={(text: string) => {
						showEditNotes.notes = text;
					}}
					entry={showEditNotes}
				/>
			)}
			{caseToMarkAsFalseMatch && (
				<MarkCaseFalseMatchModal
					entry={caseToMarkAsFalseMatch}
					onConfirm={async (reason: string) => {
						await handleMarkCaseAsFalseMatch(reason);
					}}
					onClose={() => {
						setCaseToMarkAsFalseMatch(null);
					}}
				/>
			)}
			{newLocInvitationEmailSent && (
				<Toast
					error={false}
					message="Invitation email sent"
					closeFunc={async () => {
						setShowNewLocInvitationEmailSent(false);
						return Promise.resolve();
					}}
				/>
			)}
			{isErrorState && (
				<Toast
					error={true}
					message={errorMessage}
					closeFunc={async () => {
						setIsErrorState(false);
						return Promise.resolve();
					}}
				/>
			)}
			{idCopied && (
				<Toast
					error={false}
					message="ID copied to clipboard"
					closeFunc={async () => {
						setIdCopied(false);
						return Promise.resolve();
					}}
				/>
			)}
		</Fragment>
	);
};

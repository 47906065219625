/* eslint-disable react/react-in-jsx-scope */
import { h } from "preact";
import { dlocEntry } from "../../../../../../lib/data";
import { Modal } from "../../../../../../lib/components/modal";
import CloseOutQuestionnaireView from "../CloseOutQuestionnaireView";

export const ViewQuestionnaireModal = ({
	entry,
	onClose,
}: {
	entry: dlocEntry;
	onClose: () => void;
}): JSX.Element => {
	const questionnaire = entry.closeOutQuestionnaire;
	return (
		// eslint-disable-next-line @typescript-eslint/require-await
		<Modal closeFunc={async () => onClose()} canExit={true}>
			<CloseOutQuestionnaireView questionnaire={questionnaire} />
		</Modal>
	);
};

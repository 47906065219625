/* eslint-disable react/react-in-jsx-scope */
import { h, Fragment } from "preact";
import { route } from "preact-router";
import { useContext } from "preact/hooks";
import { AuthClientContext } from "../../../../../../lib/init";
import { useAuthState } from "../../../../../../lib/init/store";
import "./styles.scss";

export const LocPlusSelected = ({
	locName,
}: {
	locName: string;
}): JSX.Element => {
	const authClient = useContext(AuthClientContext);
	const { logout } = useAuthState();

	const handleLogout = async () => {
		await authClient.logout();

		logout();

		route("/");
	};

	return (
		<Fragment>
			<main id="locPlusSelectedPage">
				<div id="titleWrapper">
					<h1>Success</h1>
				</div>
				<div id="textWrapper">
					<p>
						An LOC+ for {locName} has been selected and an email has been sent
						asking the LOC+ to complete the confirmation process.
					</p>
				</div>

				<div id="next5">
					<button className="btn primary" onClick={handleLogout}>
						Log out
					</button>
				</div>

				<p className="helperText">
					<a href="/dashboard">Go to dashboard</a>
				</p>
			</main>
		</Fragment>
	);
};

import { useAction, useSelector } from "@preact-hooks/unistore";
import { useContext, useEffect, useState } from "preact/hooks";
import {
	CaseStatus,
	CompareFunction,
	dloc,
	dlocEntry,
	loc,
} from "../../../../lib/data";
import { AppState, useAuthState } from "../../../../lib/init/store";
import { DlocClientContext } from "./components/DlocClientContext";
import { NewDlocData, NewLocData } from "../../client";

type DlocState = AppState & {
	locs?: loc[];
	unactivatedLocs?: loc[];
	unregisteredLocs?: loc[];
	deactivatedLocs?: loc[];
	cases: dlocEntry[];
	dlocs: dloc[];
};

export const useLocs = (): {
	locs: loc[];
	unactivatedLocs: loc[];
	unregisteredLocs: loc[];
	deactivatedLocs: loc[];
	refreshLocs: () => void;
	refreshUnactivatedLocs: () => void;
	refreshUnregisteredLocs: () => void;
	refreshDeactivatedLocs: () => void;
	viewLoc: (locID: string) => void;
	inviteNewLoc: (invitation: NewLocData) => Promise<boolean>;
	sendLocPlusRequest: (newLocId: string, locPlusId: string) => Promise<void>;
	reinviteNewLoc: (locId: string) => Promise<boolean>;
	getCurrentLocPlus: (locId: string) => Promise<string>;
	assignLocStar: (locId: string) => Promise<void>;
	assignKeysForShareRegeneration: (locId: string) => Promise<boolean>;
} => {
	const { loggedIn } = useAuthState();
	const locs = useSelector((state: DlocState) => state.locs ?? []);
	const unactivatedLocs = useSelector(
		(state: DlocState) => state.unactivatedLocs ?? [],
	);
	const unregisteredLocs = useSelector(
		(state: DlocState) => state.unregisteredLocs ?? [],
	);
	const deactivatedLocs = useSelector(
		(state: DlocState) => state.deactivatedLocs ?? [],
	);
	const client = useContext(DlocClientContext);
	const setLocs = useAction(
		(state: DlocState, loadedLocs: DlocState["locs"]) => ({
			locs: loadedLocs,
		}),
	);
	const setUnactivatedLocs = useAction(
		(
			state: DlocState,
			loadedUnactivatedLocs: DlocState["unactivatedLocs"],
		) => ({
			unactivatedLocs: loadedUnactivatedLocs,
		}),
	);
	const setUnregisteredLocs = useAction(
		(
			state: DlocState,
			loadedUnregisteredLocs: DlocState["unregisteredLocs"],
		) => ({
			unregisteredLocs: loadedUnregisteredLocs,
		}),
	);
	const setDeactivatedLocs = useAction(
		(
			state: DlocState,
			loadedDeactivatedLocs: DlocState["deactivatedLocs"],
		) => ({
			deactivatedLocs: loadedDeactivatedLocs,
		}),
	);

	const inviteNewLoc = async (invitation: NewLocData) =>
		client.inviteNewLoc(invitation, null);

	const sendLocPlusRequest = async (newLocId: string, locPlusId: string) =>
		client.sendLocActivationRequest(newLocId, locPlusId);

	const reinviteNewLoc = async (locId: string) =>
		client.inviteNewLoc(null, locId);

	const getCurrentLocPlus = async (locId: string) =>
		client.getCurrentLocPlus(locId);

	const refreshLocs = () => {
		if (loggedIn) {
			void client.getLOCs().then((loadedLocs) => {
				setLocs(loadedLocs);
			});
		} else {
			setLocs([]);
		}
	};

	const refreshUnactivatedLocs = () => {
		if (loggedIn) {
			void client.getUnactivatedLocs().then((loadedUnactivatedLocs) => {
				setUnactivatedLocs(loadedUnactivatedLocs);
			});
		} else {
			setUnactivatedLocs([]);
		}
	};

	const refreshUnregisteredLocs = () => {
		if (loggedIn) {
			void client.getUnregisteredLocs().then((loadedUnregisteredLocs) => {
				setUnregisteredLocs(loadedUnregisteredLocs);
			});
		} else {
			setUnregisteredLocs([]);
		}
	};

	const refreshDeactivatedLocs = () => {
		if (loggedIn) {
			void client.getDeactivatedLocs().then((loadedDeactivatedLocs) => {
				setDeactivatedLocs(loadedDeactivatedLocs);
			});
		} else {
			setDeactivatedLocs([]);
		}
	};

	const viewLoc = (locID: string) => {
		void client.viewLoc(locID);
	};

	const assignLocStar = async (locId: string) => {
		await client.assignLocStar(locId);
	};

	const assignKeysForShareRegeneration = async (locId: string) =>
		await client.assignKeysForShareRegeneration(locId);

	useEffect(() => {
		refreshLocs();
		refreshUnactivatedLocs();
		refreshUnregisteredLocs();
		refreshDeactivatedLocs();
	}, [loggedIn]);

	return {
		locs,
		unactivatedLocs,
		unregisteredLocs,
		deactivatedLocs,
		refreshLocs,
		refreshUnactivatedLocs,
		refreshUnregisteredLocs,
		refreshDeactivatedLocs,
		viewLoc,
		inviteNewLoc,
		sendLocPlusRequest,
		reinviteNewLoc,
		getCurrentLocPlus,
		assignLocStar,
		assignKeysForShareRegeneration,
	};
};

export const useCases = (): {
	clearAssignCase: (entry: dlocEntry, locId: string, userId: string) => void;
	cases: dlocEntry[];
	refreshCases: () => void;
	assignCase: (entry: dlocEntry, locId: string) => void;
	casesLoaded: boolean;
	sort: (comparer: CompareFunction) => void;
	saveNotes: (caseId: string, notes: string) => Promise<void>;
	updateCaseStatus: (entry: dlocEntry, status: CaseStatus) => Promise<void>;
} => {
	const { loggedIn } = useAuthState();
	const cases = useSelector((state: DlocState) => state.cases ?? []);
	const client = useContext(DlocClientContext);
	const setCases = useAction(
		(state: DlocState, loadedCases: DlocState["cases"]) => ({
			cases: loadedCases,
		}),
	);
	const [casesLoaded, setCasesLoaded] = useState<boolean>(false);

	const refreshCases = () => {
		if (loggedIn) {
			setCasesLoaded(false);
			void client
				.getCases()
				.then((loadedCases) => {
					setCases(loadedCases);
				})
				.finally(() => setCasesLoaded(true));
		} else {
			setCases([]);
		}
	};

	const assignCase = (entry: dlocEntry, locId: string) => {
		void client.assignCase(entry.id, locId, entry.userID).then(() => {
			refreshCases();
		});
	};

	const clearAssignCase = (entry: dlocEntry, locId: string, userId: string) => {
		void client.clearAssignCase(entry.id, locId, userId).then(() => {
			refreshCases();
		});
	};

	const sort = (comparer: CompareFunction) => {
		cases.sort(comparer);
	};

	const saveNotes = async (caseId: string, notes: string) => {
		await client.saveNotes(caseId, notes);
	};

	const updateCaseStatus = async (entry: dlocEntry, status: CaseStatus) => {
		await client.updateStatus(entry.id, status);
	};

	useEffect(refreshCases, [loggedIn]);

	return {
		cases,
		refreshCases,
		assignCase,
		clearAssignCase,
		casesLoaded,
		sort,
		saveNotes,
		updateCaseStatus,
	};
};

export const useContactInfo = (): {
	contact: { name: string; email: string; username: string };
	setContactInfo: (info: { name: string; email: string }) => Promise<void>;
} => {
	const defaultState = {
		name: "",
		email: "",
	};
	const client = useContext(DlocClientContext);
	const { loggedIn } = useAuthState();
	const [profile, setProfile] = useState(defaultState);

	const loadProfile = () => {
		if (loggedIn) {
			void client.getContactInfo().then((result) => {
				setProfile(result);
			});
		} else {
			setProfile(defaultState);
		}
	};

	const setContactInfo = async (info: { name: string; email: string }) => {
		await client.updateContactInfo(info.name, info.email);
		// We didnt' throw, so we know it's good
		setProfile(info);
	};

	useEffect(loadProfile, [loggedIn]);

	return {
		contact: { ...profile, username: client.username },
		setContactInfo,
	};
};

export const useDlocs = (): {
	dlocs: dloc[];
	refreshDlocs: () => void;
	inviteNewDloc: (data: NewDlocData) => Promise<boolean>;
	resendInvitation: (dlocId: string) => Promise<boolean>;
	activateDloc: (dlocId: string) => Promise<void>;
	isActivated: (dlocId: string) => boolean;
} => {
	const { loggedIn } = useAuthState();
	const dlocs = useSelector((state: DlocState) => state.dlocs ?? []);
	const client = useContext(DlocClientContext);
	const setDlocs = useAction(
		(state: DlocState, loadedDlocs: DlocState["dlocs"]) => ({
			dlocs: loadedDlocs.sort((dloc1, dloc2) => {
				if (dloc1.name < dloc2.name) {
					return -1;
				}

				if (dloc2.name < dloc1.name) {
					return 1;
				}

				return 0;
			}),
		}),
	);

	const refreshDlocs = () => {
		if (loggedIn) {
			void client.getAllDlocs().then((loadedDlocs) => {
				setDlocs(loadedDlocs);
			});
		} else {
			setDlocs([]);
		}
	};

	const inviteNewDloc = async (data: NewDlocData) => client.inviteNewDloc(data);

	const resendInvitation = async (dlocId: string) =>
		client.inviteNewDloc(null, dlocId);

	const activateDloc = async (dlocId: string): Promise<void> =>
		client.activateNewDloc(dlocId);

	const isActivated = (dlocId: string): boolean => {
		const identifiedDloc = dlocs.filter((dLoc) => dLoc.id === dlocId)[0];
		return identifiedDloc && identifiedDloc.isActivated;
	};

	useEffect(() => {
		refreshDlocs();
	}, [loggedIn]);

	return {
		dlocs,
		refreshDlocs,
		inviteNewDloc,
		resendInvitation,
		activateDloc,
		isActivated,
	};
};

/* eslint-disable react/react-in-jsx-scope */
import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import * as emailValidator from "email-validator";

import { NewLocData } from "../../../../client";
import { Modal } from "../../../../../../lib/components/modal";

import "./styles.scss";

export const InviteModal = ({
	onClose,
	onInvite,
}: {
	onClose: () => void;
	onInvite: (newLocData: NewLocData) => void;
}): JSX.Element => {
	const [validEmail, setValidEmail] = useState<boolean>(false);
	const [formFilled, setFormFilled] = useState<boolean>(false);
	const [formState, setFormState] = useState<NewLocData>({
		name: "",
		email: "",
		practice: "",
		state: "",
	});

	useEffect(() => {
		setFormFilled(
			formState.name !== "" && formState.email !== "" && formState.state !== "",
		);
		setValidEmail(emailValidator.validate(formState.email));
	}, [formState]);

	return (
		// eslint-disable-next-line @typescript-eslint/require-await
		<Modal closeFunc={async () => onClose()} canExit={true}>
			<div id="inviteModal">
				<h3>Add a New Legal Options Counselor</h3>
				<div>
					<label htmlFor="nameInput">LOC Name</label>
					<input
						id="nameInput"
						type="text"
						onChange={(evt) => {
							setFormState({
								...formState,
								name: evt.currentTarget.value.trim(),
							});
						}}
					/>

					<label htmlFor="emailInput">LOC Email</label>
					<input
						id="emailInput"
						type="text"
						onChange={(evt) => {
							setFormState({
								...formState,
								email: evt.currentTarget.value.trim(),
							});
						}}
					/>

					<label htmlFor="practiceInput">Practice Name</label>
					<input
						id="practiceInput"
						type="text"
						onChange={(evt) => {
							setFormState({
								...formState,
								practice: evt.currentTarget.value.trim(),
							});
						}}
					/>

					<label htmlFor="stateInput">State of Practice</label>
					<input
						id="stateInput"
						type="text"
						onChange={(evt) => {
							setFormState({
								...formState,
								state: evt.currentTarget.value.trim(),
							});
						}}
					/>
				</div>
			</div>
			{!validEmail && formState.email !== "" && (
				<div className="pwerror" style={{ paddingTop: "25px" }}>
					Email address is invalid
				</div>
			)}
			<div id="codesButtons" className="hideOnPrint topPadding">
				<a href="/dashboard">
					<button
						id="completeButton"
						className="secondaryButton"
						onClick={onClose}
					>
						Cancel
					</button>
					<button
						disabled={!(formFilled && validEmail)}
						className={
							formFilled && validEmail ? "primaryButton" : "secondaryButton"
						}
						onClick={() => onInvite(formState)}
					>
						Save changes
					</button>
				</a>
			</div>
		</Modal>
	);
};

/* eslint-disable react/react-in-jsx-scope */
import { h } from "preact";
import { dlocEntry, formatStatus } from "../../../../../../lib/data";
import { Modal } from "../../../../../../lib/components/modal";
import { useState } from "preact/hooks";

export const MarkCaseFalseMatchModal = ({
	entry,
	onConfirm,
	onClose,
}: {
	entry: dlocEntry;
	onConfirm: (reason: string) => void;
	onClose: () => void;
}): JSX.Element => {
	const [reason, setReason] = useState<string>("");
	return (
		// eslint-disable-next-line @typescript-eslint/require-await
		<Modal closeFunc={async () => onClose()} canExit={false}>
			<div id="falseMatchModal">
				<h2>Mark Case as a False Match</h2>
				<p>
					<b>Case ID:</b> {entry.id}
				</p>
				<p>
					<b>Current Status:</b> {formatStatus(entry.status)}
				</p>
				<p>
					<b>Assigned LOC:</b> {entry.assignedLOC?.name ?? "None"}
				</p>

				<h3>Reason You Believe this is a False Match</h3>
				<p>
					<textarea
						value={reason}
						style={{ width: "100%", fontSize: "12pt", color: "#225a75" }}
						onChange={(e) => {
							setReason(e.currentTarget.value);
						}}
					/>
				</p>

				<div id="falseMatchButtons" className="topPadding">
					<a href="/dashboard">
						<button className="secondaryButton" onClick={onClose}>
							Cancel
						</button>
						<button
							disabled={!reason}
							className="primaryButton"
							onClick={() => onConfirm(reason)}
						>
							Mark as False Match
						</button>
					</a>
				</div>
			</div>
		</Modal>
	);
};
